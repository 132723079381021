<template>
  <div class="dashboard-template" v-show="$store.state.auth.token">
    <h4 v-if="error">{{ error }}</h4>
    <!-- VIEW DESKTOP -->
    <div class="page-container-requests" v-if="!isMobile">
      <HeaderProfile />
      <TableRequests />
    </div>
    <!-- VIEW MOBILE -->
    <div class="page-container-requests--mobile" v-if="isMobile">
      <MobileHeaderProfile />
      <div class="result-search" v-if="store.search">
        <div>
          <div
            class="result-search__loading"
            v-if="store.statusSearch === 1.1 && store.status === 1"
          >
            <b-spinner small variant="white"></b-spinner>
            buscando aguarde...
          </div>
          <div class="result-search__error" v-if="store.statusSearch === -1">
            Erro ao buscar, tente novamente.
          </div>
        </div>
        <div
          class="result-search__empty"
          v-if="
            getRequests.length === 0 &&
            store.statusSearch === 0 &&
            store.status === 0
          "
        >
          <h3 class="m-4 mt-5">Nenhum foi encontrado</h3>
        </div>
      </div>
      <div
        v-else-if="store.status === 1 && !store.statusPageLoading"
        style="width: 100vw"
      >
        <h3 class="m-4 mt-5 pt-5 text-white text-center">
          <b-spinner
            variant="text-white"
            class="pr-3"
            style="width: 1.3rem; height: 1.3rem; border-width: 2px"
          ></b-spinner>
          buscando...
        </h3>
      </div>
      <div
        class="
          result-search__total
          text-white
          d-flex
          justify-content-between
          align-items-center
        "
        v-if="getRequests.length && store.statusSearch !== -1"
      >
        <div>
          <b>
            {{
              store.statusSelected > 0
                ? store.tipoStatus.find((e) => e.id === store.statusSelected)
                    .count +
                  " / " +
                  store.totalSolicitacoes
                : store.totalSolicitacoes
            }}
          </b>
          {{ store.totalSolicitacoes === 1 ? "proposta" : "propostas" }}
        </div>
        <div style="font-size: 11px; padding-right: 1rem">
          {{
            store.statusSelected > 0
              ? "status: " +
                store.tipoStatus.find((e) => e.id === store.statusSelected).text
              : ""
          }}
        </div>
      </div>
      <template v-if="store.statusSearch !== -1">
        <div>
          <CardRequest
            v-for="r in getRequests"
            :key="'card_' + r.id"
            v-bind="{
              id: r.id,
              dateTime: formatDate(r.createdAt),
              amount: r.valorEmprestimo,
              leadName: r.leadNome,
              leadCpf: r.leadCpf,
              leadCnpj: r.leadCnpj,
              type: 'Home Equity',
              status: formatStatus(r.ultimoStatusOp),
              lastComment: r.ultimoStatusOp ? r.ultimoStatusOp.comentario : '',
              commission: toCurrency(0.0),
            }"
          />
          <page-loading />
        </div>
      </template>
    </div>
    <!-- MODALS -->
    <!-- modal enviar arquivos -->
    <b-modal id="modal-anexos" hide-footer title="Enviar Arquivos">
      <file-upload
        :solic-id="store.files.solicIdSelected"
        :callbackSuccess="callbackSuccessUploadFiles"
      >
        <template #send-files="{ countFiles, uploadFiles, status }">
          <div v-if="countFiles > 0 && status !== 1" class="mt-5">
            <button
              class="btn btn-primary"
              :disabled="!countFiles"
              @click="uploadFiles()"
            >
              Enviar {{ countFiles }}
              {{ countFiles === 1 ? "arquivo" : "arquivos" }}
            </button>
          </div>
          <div v-else-if="status === 1" class="text-primary">
            <b-spinner small variant="primary"></b-spinner>
            Enviando...
          </div>
          <div v-if="status === -1" class="text-danger mt-3">
            Erro ao enviar arquivos, tente novamente.
          </div>
        </template>
        <template #success>
          <b-alert show variant="success">
            <div>
              <font-awesome-icon icon="fa-solid fa-check" />
              Os arquivos foram enviados com sucesso
            </div>
          </b-alert>
        </template>
      </file-upload>
    </b-modal>
    <!-- modal consultar arquivos -->
    <b-modal id="modal-anexos-enviados" hide-footer title="Arquivos Enviados">
      <div class="d-flex">
        <div class="show-file-container w-100">
          <div
            class="text-center"
            v-if="
              store.files.attachFiles.files.length === 0 &&
              store.files.attachFiles.status === 0
            "
          >
            <font-awesome-icon
              icon="fa-solid fa-file"
              class="text-muted fa-2xl"
              style="opacity: 0.2; font-size: 56px"
            />
            <h6 class="text-muted pt-3">Nenhum arquivo foi enviado.</h6>
          </div>
          <template v-if="store.files.attachFiles.status === 0">
            <div
              class="d-flex show-file-item justify-content-between"
              v-for="(item, i) in store.files.attachFiles.files"
              :key="i"
            >
              <div class="d-flex">
                <div class="show-file-img">
                  <font-awesome-icon
                    :icon="`fa-solid ${getFileIcon(item.tipo)}`"
                    :class="`${getTextColor(item.tipo)} fa-2xl`"
                  />
                </div>
                <div class="d-flex flex-column">
                  <h5
                    class="show-file-item-filename"
                    :title="item.nome.replace(/_/g, ' ')"
                  >
                    {{ item.nome.replace(/_/g, " ") }}
                  </h5>
                  <p class="m-0">
                    <small>{{ getSize(item.tamanho) }}</small>
                  </p>
                  <p>
                    <small>
                      enviado em:
                      {{ new Date(item.createdAt).toLocaleString() }}
                    </small>
                  </p>
                </div>
              </div>

              <div
                class="
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                "
                @click="download(item.id, item.nome, item.tipo, i)"
              >
                <div
                  class="
                    btn-download-file
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                  "
                  v-if="downloadStatus.length === 0 || downloadStatus[i] <= 0"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-external-link-alt"
                    class="pb-2"
                  />
                  <small>visualizar</small>
                </div>
                <div
                  v-else-if="downloadStatus[i] === 1"
                  class="text-primary text-center"
                >
                  <b-spinner small variant="primary"></b-spinner>
                  <div>baixando...</div>
                </div>
                <div v-if="downloadStatus[i] === -1" class="text-danger mt-3">
                  <small>Erro ao baixar arquivos, tente novamente.</small>
                </div>
              </div>
            </div>
          </template>
          <div
            v-else-if="store.files.attachFiles.status === -1"
            class="text-danger mt-3"
          >
            Erro ao obter os arquivos, feche a janela e tente novamente.
          </div>
          <div v-else class="text-primary">
            <b-spinner small variant="primary"></b-spinner>
            Carregando...
          </div>
        </div>
      </div>
    </b-modal>
    <!-- modal encerrar solicitacao -->
    <b-modal
      id="modal-encerrar-solic"
      hide-footer
      :title="`Encerrar Solicitação #${store.cancelSolicId}`"
    >
      <div class="d-flex mb-4">Deseja realmente encerrar a solicitação ?</div>
      <div class="d-flex justify-content-between">
        <b-btn
          size="sm"
          variant="primary"
          class="text-white"
          style="padding: 6px 33px"
          @click="onCancelSolicYes"
          >Sim</b-btn
        >
        <b-btn
          size="sm"
          variant="secondary"
          class="text-white"
          style="padding: 6px 33px"
          @click="onCancelSolicNo"
          >Não</b-btn
        >
      </div>
    </b-modal>
  </div>
</template>


<script>
import "@/assets/css/main.css";
import FileUpload from "@/components/FileUpload";
import CardRequest from "@/components/mobile/CardRequest";
import MobileHeaderProfile from "@/components/mobile/HeaderProfile";
import HeaderProfile from "@/components/desktop/HeaderProfile";
import TableRequests from "@/components/desktop/TableRequests";
import PageLoading from "./PageLoading";

export default {
  name: "DashboardTemplate",
  components: {
    FileUpload,
    CardRequest,
    MobileHeaderProfile,
    HeaderProfile,
    TableRequests,
    PageLoading,
  },
  data() {
    return {
      user: {
        url: "https://selfcredi.com.br",
      },
      error: null,
      totalComissao: "0",
      downloadStatus: [],
    };
  },

  watch: {
    "store.search"(searchValue) {
      if (!searchValue)
        this.$store.dispatch("registerPageObserver", {
          pageLoading: document.querySelector(".page-loading"),
        });
    },
  },

  methods: {
    async download(id, filename, mimetype, index) {
      this.downloadStatus = [];
      try {
        this.downloadStatus[index] = 1;
        const data = await this.$store.dispatch(
          "files/downloadFileAffiliateBySolic",
          {
            id,
          }
        );

        window.download(data, filename, mimetype);
        this.downloadStatus = [];
      } catch (err) {
        console.error(err);
        this.downloadStatus[index] = -1;
      }
    },
    formatDate(date) {
      return /\d{2}\/\d{2}\/\d{4}/.test(date)
        ? date.substring(0, 10)
        : "invalid format date";
    },
    formatStatus(ultimoStatusOp) {
      return ultimoStatusOp
        ? ultimoStatusOp.status.replace(/_/g, " ").toUpperCase()
        : "";
    },
    getStatusSolicitacao(arrayStatus) {
      return arrayStatus[arrayStatus.length - 1];
    },
    callbackSuccessUploadFiles() {
      setTimeout(() => this.$bvModal.hide("modal-anexos"), 2000);
    },
    getFileIcon(mimetype) {
      const [type, ext] = mimetype.split("/");
      if (type === "image") {
        return "fa-image";
      } else {
        switch (ext) {
          case "pdf":
            return "fa-file-pdf";
          case "doc":
          case "docx":
            return "fa-file-word";
          case "zip":
            return "fa-file-zipper";
          default:
            return "fa-file-lines";
        }
      }
    },
    getTextColor(mimetype) {
      const [type, ext] = mimetype.split("/");
      if (type === "image") {
        return "text-primary";
      } else if (ext === "pdf") {
        return "text-danger";
      } else if (ext === "zip") {
        return "text-warning";
      } else {
        return "text-primary";
      }
    },
    getSize(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
    toCurrency(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    onCancelSolicYes() {
      this.$bvModal.hide("modal-encerrar-solic");
      this.$store.dispatch("cancelSolic", { id: this.store.cancelSolicId });
    },
    onCancelSolicNo() {
      this.$bvModal.hide("modal-encerrar-solic");
    },
  },

  async beforeMount() {
    this.$store.dispatch("getSolicitacoes", { page: 1 }).catch((error) => {
      if (error?.response?.status == 401) {
        localStorage.setItem("token", undefined);
        this.$store.dispatch("auth/token", null);
        this.auth = localStorage.getItem("token") !== "undefined";
        this.$router.push("/");
      }
    });
  },

  async mounted() {
    this.totalComissao =
      "Total comissão: " +
      new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(0.0);

    this.$store.dispatch("registerPageObserver", {
      pageLoading: document.querySelector(".page-loading"),
    });

    this.$store.dispatch("getFiltroStatusAfiliado");
  },

  computed: {
    haveRequests() {
      return Object.entries(this.store.requests).length > 0;
    },
    store() {
      return this.$store.state;
    },
    isMobile() {
      return window.outerWidth <= 960;
    },
    getRequests() {
      return this.store.search ? this.store.resultSearch : this.store.requests;
    },
  },
};
</script>

<style>
.dashboard-template {
  background: #5d6180;
  min-height: calc(100vh - 58px);
}
.page-container-requests {
  padding: 10px 0 0;
}

.page-container-requests > div {
  text-align: center;
}

.dashboard-template .btn.btn-primary {
  color: white;
}

#modal-anexos___BV_modal_outer_,
#modal-anexos-enviados___BV_modal_outer_ {
  z-index: 99999 !important;
}

#modal-anexos .btn {
  color: white;
}

.comentario-op-label {
  font-size: 12px;
  color: #222;
  font-weight: bold;
}

.comentario-op-text {
  font-style: italic;
}

.comentario-op-btn-close {
  position: absolute;
  right: 0;
}

.comentario-op-no-comments {
  font-style: italic;
}

.show-file-container h5 {
  margin: 0;
}
.show-file-item {
  border-bottom: solid 1px #ccc;
  margin-bottom: 8px;
  padding-top: 8px;
}
.show-file-img {
  margin-right: 18px;
}
.show-file-item-filename {
  display: block;
  text-overflow: ellipsis;
  width: 349px;
  overflow: hidden;
}

.dashboard-template {
  transition: all 0.6s;
  transform: translateX(0%);
}

.view-details-request-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 940;
  width: 100%;
  background: #fafafa;
  min-height: 100vh;
  transition: all 0.488s ease-in-out;
  transform: translateX(100%);
  /* opacity: 0; */
}
.view-details-request-container[data-show] {
  transform: translateX(0);
  opacity: 1;
}

.result-search {
  color: #fff;
}

.result-search__total {
  margin-left: 1rem;
}

.result-search__loading,
.result-search__error {
  margin-left: 1rem;
}

.btn-download-file {
  cursor: pointer;
  color: #333;
}

.btn-download-file:hover {
  color: var(--bs-primary);
}

@media (max-width: 960px) {
  .dashboard-template {
    margin-top: 0 !important;
    padding-bottom: 6rem !important;
  }

  .page-title {
    font-size: 1.15rem !important;
    font-weight: 800 !important;
    margin: 0 !important;
  }

  .page-container-requests--mobile {
    position: relative;
    top: -1px;
  }

  .inner-block-dash {
    padding: 0.8rem;
    margin-top: 0 !important;
    box-shadow: 0 8px 20px -5px rgb(0 0 0 / 49%),
      -7px -20px 100px -80px rgb(0 0 0 / 49%) inset;
  }

  .affiliate-id-label,
  .affiliate-link-label {
    font-size: 1rem;
  }
  .affiliate-id {
    font-size: 0.998rem;
  }
  .affiliate-link {
    font-size: 0.855rem;
    overflow: auto hidden;
    white-space: nowrap;
    width: 100%;
    background: #eaeaea;
    border-radius: 4px;
    padding: 8px 5px 8px;
  }

  .file-preview {
    height: 82px !important;
  }

  .show-file-item-filename {
    width: 13rem;
  }
}
</style>