<template>
  <v-app>
    <b-container class="scroll-container">
      <div class="text-center mt-13">
        <v-btn color="error" @click="$router.push('/request')">Voltar</v-btn>
      </div>

      <h3 class="text-center mt-5 mb-5">
        <b>Empréstimos</b>
      </h3>

      <div class="text-center mb-1">
        <v-btn
          color="black"
          style="color: #fff"
          @click="aba.Veiculo = !aba.Veiculo"
        >
          <v-icon v-if="aba.Veiculo" color="white">
            mdi-radiobox-marked
          </v-icon>
          <v-icon v-if="aba.Veiculo == false" color="white">
            mdi-radiobox-blank
          </v-icon>
          <b color="white">Veículo</b>
        </v-btn>
        <v-btn
          class="ml-5 mr-5"
          color="black"
          style="color: #fff"
          @click="aba.Imovel = !aba.Imovel"
        >
          <v-icon v-if="aba.Imovel" color="white"> mdi-radiobox-marked </v-icon>
          <v-icon v-if="aba.Imovel == false" color="white">
            mdi-radiobox-blank
          </v-icon>
          <b color="white">Imóvel</b>
        </v-btn>
        <v-btn color="black" style="color: #fff" @click="aba.CNPJ = !aba.CNPJ">
          <v-icon v-if="aba.CNPJ" color="white"> mdi-radiobox-marked </v-icon>
          <v-icon v-if="aba.CNPJ == false" color="white">
            mdi-radiobox-blank
          </v-icon>
          <b color="white">CNPJ</b>
        </v-btn>
      </div>

      <v-form class="pa-3" ref="form1Ref" v-show="aba.Veiculo">
        <b-card header="Veículo">
          <b-row>
            <b-col md="4">
              <v-text-field
                :disabled="sendedForm1"
                counter="4"
                label="Ano do Veículo"
                prepend-inner-icon="mdi-calendar-range"
                v-model.trim="form1.ano_veiculo"
                v-mask="'####'"
                :rules="[(v) => !!validarAno(v)]"
                :style="
                  validarAno(form1.ano_veiculo)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
                @blur="obterMarcas"
              />
            </b-col>

            <b-col md="4">
              <v-text-field
                :disabled="sendedForm1"
                counter="9"
                label="CEP Residência"
                prepend-inner-icon="mdi-home-map-marker"
                :id="form1.id_cep"
                v-model.trim="form1.cep"
                v-mask="'#####-###'"
                :rules="[form1.cep_status]"
                :style="
                  form1.cep_status
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="4">
              <v-text-field
                :disabled="sendedForm1"
                counter="10"
                label="Data de Nascimento"
                prepend-inner-icon="mdi-calendar-range"
                v-model.trim="form.data_nasc"
                v-mask="'##/##/####'"
                :rules="[(v) => !!validarData(v)]"
                :style="
                  validarData(form.data_nasc)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <v-autocomplete
                :disabled="sendedForm1"
                v-model="form.selectedOcupacao"
                :items="ocupacoes"
                label="Qual ocupação?"
                item-value="value.tag"
                item-text="value.texto"
                return-object
                prepend-inner-icon="mdi-account-tie"
                :rules="[(v) => !!v]"
                :style="
                  form.selectedOcupacao
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>

            <b-col md="4">
              <v-currency-field
                :disabled="sendedForm1"
                label="Renda Mensal"
                prepend-inner-icon="mdi-currency-usd"
                v-model="form.renda"
                :rules="[(v) => !!validarDifZero(v)]"
                :style="
                  validarDifZero(form.renda)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>

            <b-col md="4">
              <v-text-field
                :disabled="sendedForm1"
                counter="8"
                label="Placa do veículo"
                prepend-inner-icon="mdi-car"
                v-model.trim="form1.placa_veiculo"
                v-mask="['AAA-#X##']"
                :rules="[(v) => !!validarPlaca(v)]"
                :style="
                  form1.placa_veiculo
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <v-currency-field
                :disabled="sendedForm1"
                label="Valor do Veículo"
                prepend-inner-icon="mdi-currency-usd"
                v-model="form1.valor_veiculo"
                :rules="[(v) => !!validarDifZero(v)]"
                :style="
                  validarDifZero(form1.valor_veiculo)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="4">
              <v-autocomplete
                :disabled="sendedForm1"
                v-model="form1.selectedMarca"
                :items="marcasCarros"
                label="Marca Veículo"
                return-object
                item-value="value"
                item-text="text"
                prepend-inner-icon="mdi-car"
                :rules="[(v) => !!v]"
                no-data-text="Digite o ano novamente"
                :style="
                  form1.selectedMarca
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="4">
              <v-autocomplete
                :disabled="sendedForm1"
                v-model="form1.selectedModelo"
                :items="modelosCarros"
                label="Modelo Veículo"
                return-object
                item-value="value"
                item-text="text"
                prepend-inner-icon="mdi-car"
                :rules="[(v) => !!v]"
                no-data-text="Primeiro escolha uma marca"
                :style="
                  form1.selectedModelo
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
          </b-row>
        </b-card>
      </v-form>

      <v-form class="pa-3" ref="form2Ref" v-show="aba.Imovel">
        <b-card header="Imóvel">
          <b-row>
            <b-col md="4">
              <v-currency-field
                :disabled="sendedForm2"
                label="Renda Mensal"
                prepend-inner-icon="mdi-currency-usd"
                v-model="form.renda"
                :rules="[(v) => !!validarDifZero(v)]"
                :style="
                  validarDifZero(form.renda)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="4">
              <v-text-field
                :disabled="sendedForm2"
                counter="9"
                label="CEP Residência"
                prepend-inner-icon="mdi-home-map-marker"
                :id="form2.id_cep"
                v-model.trim="form2.cep"
                v-mask="'#####-###'"
                :rules="[form2.cep_status]"
                :style="
                  form2.cep_status
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="4">
              <v-text-field
                :disabled="sendedForm2"
                counter="10"
                label="Data de Nascimento"
                prepend-inner-icon="mdi-calendar-range"
                v-model.trim="form.data_nasc"
                v-mask="'##/##/####'"
                :rules="[(v) => !!validarData(v)]"
                :style="
                  validarData(form.data_nasc)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <v-autocomplete
                :disabled="sendedForm2"
                v-model="form.selectedOcupacao"
                :items="ocupacoes"
                label="Qual ocupação?"
                item-value="value.tag"
                item-text="value.texto"
                return-object
                prepend-inner-icon="mdi-account-tie"
                :rules="[(v) => !!v]"
                :style="
                  form.selectedOcupacao
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="4">
              <v-text-field
                :disabled="sendedForm2"
                counter="9"
                label="CEP Imóvel Garantia"
                prepend-inner-icon="mdi-map-marker"
                :id="form2.id_cep_imovel"
                v-model.trim="form2.cep_imovel"
                v-mask="'#####-###'"
                :rules="[form2.cep_imovel_status]"
                :style="
                  form2.cep_imovel_status
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="4">
              <v-text-field
                :disabled="sendedForm2"
                label="Número Imóvel Garantia"
                prepend-inner-icon="mdi-format-list-numbered"
                v-model.trim="form2.numero_imovel"
                v-mask="'#######'"
                :rules="[(v) => !!v]"
                :style="
                  form2.numero_imovel
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <v-autocomplete
                :disabled="sendedForm2"
                v-model="form2.selectedImovelFinanc"
                :items="imoveisFinanciado"
                label="O Imóvel é financiado?"
                item-value="value.tag"
                item-text="value.texto"
                return-object
                prepend-inner-icon="mdi-home"
                :rules="[(v) => !!v]"
                :style="
                  form2.selectedImovelFinanc
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="4">
              <v-currency-field
                :disabled="sendedForm2"
                label="Valor Imóvel Garantia"
                prepend-inner-icon="mdi-currency-usd"
                v-model="form2.valor_imovel"
                :rules="[(v) => !!validarDifZero(v)]"
                :style="
                  validarDifZero(form2.valor_imovel)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="4">
              <v-currency-field
                :disabled="sendedForm2"
                label="Valor do saldo devedor"
                prepend-inner-icon="mdi-currency-usd"
                v-model="form2.saldo_devedor"
                :rules="[(v) => !!validarDifZero(v)]"
                :style="
                  validarDifZero(form2.saldo_devedor)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <v-autocomplete
                :disabled="sendedForm2"
                v-model="form2.selectedTipoImovel"
                :items="tiposImovel"
                label="Tipo do imóvel"
                item-value="value.tag"
                item-text="value.texto"
                return-object
                prepend-inner-icon="mdi-home"
                :rules="[(v) => !!v]"
                :style="
                  form2.selectedTipoImovel
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="6">
              <v-autocomplete
                :disabled="sendedForm2"
                v-model="form2.selectedRegImovel"
                :items="registrosImovel"
                label="Possui Escritura do Imóvel?"
                item-value="value.tag"
                item-text="value.texto"
                return-object
                prepend-inner-icon="mdi-home"
                :rules="[(v) => !!v]"
                :style="
                  form2.selectedRegImovel
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
          </b-row>
        </b-card>
      </v-form>

      <v-form class="pa-3" ref="form3Ref" v-show="aba.CNPJ">
        <b-card header="CNPJ">
          <b-row>
            <b-col md="4">
              <v-text-field
                :disabled="sendedForm3"
                counter="18"
                label="CNPJ"
                prepend-inner-icon="mdi-account-multiple"
                v-model.trim="form3.CNPJ"
                v-mask="'##.###.###/####-##'"
                :rules="[(v) => !!validarCNPJ(v)]"
                :style="
                  validarCNPJ(form3.CNPJ)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>

            <b-col md="4">
              <v-currency-field
                :disabled="sendedForm3"
                label="Faturamento Mensal"
                prepend-inner-icon="mdi-currency-usd"
                v-model="form3.faturamento_mensal"
                :rules="[(v) => !!validarDifZero(v)]"
                :style="
                  validarDifZero(form3.faturamento_mensal)
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="4">
              <v-autocomplete
                :disabled="sendedForm3"
                v-model="form3.selectedRelacEmpresa"
                :items="relacoesEmpresa"
                label="Relação Pessoa e Empresa?"
                item-value="value.tag"
                item-text="value.texto"
                return-object
                prepend-inner-icon="mdi-account-tie"
                :rules="[(v) => !!v]"
                :style="
                  form3.selectedRelacEmpresa
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col md="6">
              <v-autocomplete
                :disabled="sendedForm3"
                v-model="form3.selectedPort"
                :items="portes"
                label="Porte"
                item-value="value.tag"
                item-text="value.texto"
                return-object
                prepend-inner-icon="mdi-city-variant"
                :rules="[(v) => !!v]"
                :style="
                  form3.selectedPort
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
                @change="onSelecPort"
              />
            </b-col>
            <b-col md="6">
              <v-text-field
                :disabled="sendedForm3"
                label="Nome Fiador/Aval."
                prepend-inner-icon="mdi-account"
                v-model.trim="form3.nome_fiador"
                :rules="[(v) => !!v]"
                :style="
                  form3.nome_fiador
                    ? 'border-bottom: 2px solid #2DA80E !important'
                    : 'border-bottom: 2px solid #FF6060 !important'
                "
              />
            </b-col>
            <b-col
              cols="12"
              v-show="
                form3.selectedPort && form3.selectedPort.value.tag === 'mei'
              "
            >
              <b-row>
                <b-col md="6">
                  <div class="pb-3">
                    O Fiador é conjuge do Proprietário dessa Empresa?
                  </div>
                  <v-radio-group
                    v-model="form3.fiador_conjuge"
                    :rules="[(v) => !!v]"
                    style="max-width: 100px"
                  >
                    <v-radio
                      :value="fiador_conjuge[0]"
                      label="Sim"
                      class="pb-3"
                    />
                    <v-radio :value="fiador_conjuge[1]" label="Não" />
                  </v-radio-group>
                </b-col>
                <b-col md="6">
                  <div class="pb-3">
                    O Fiador é remunerado ou trabalha para o Proprietário dessa
                    Empresa?
                  </div>
                  <v-radio-group
                    v-model="form3.fiador_remunerado"
                    :rules="[(v) => !!v]"
                    style="max-width: 100px"
                  >
                    <v-radio
                      :value="fiador_remunerado[0]"
                      label="Sim"
                      class="pb-3"
                    />
                    <v-radio :value="fiador_remunerado[1]" label="Não" />
                  </v-radio-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </v-form>

      <div class="text-center mt-3">
        <v-btn
          v-if="aba.Veiculo || aba.Imovel || aba.CNPJ"
          color="primary"
          :disabled="btnEnviarFormFim"
          @click="fimForm"
        >
          Finalizar Solicitação
          <v-progress-circular
            v-if="btnEnviarFormFim"
            class="ml-3"
            indeterminate
            color="green"
          />
        </v-btn>
      </div>
    </b-container>
  </v-app>
</template>

<script>
import { Endpoints } from "../shared/endpoints";
import {
  validarCNPJ,
  //validarCEP,
  validarDifZero,
  validarData,
  validarPlaca,
  //validarEmail,
  //validarCPF,
  //validarNome,
  //validarTelefone,
} from "./scripts/validators";
import axios from "axios";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  name: "RequestPage",
  data() {
    return {
      btnEnviarFormFim: false,
      aba: { Veiculo: false, Imovel: false, CNPJ: false },
      money: {
        decimal: ",",
        thousands: ".",
      },

      // opções para selects:
      ocupacoes: [
        {
          value: {
            id: "6",
            tag: "assalariado",
            texto: "Assalariado",
          },
        },
        {
          value: {
            id: "7",
            tag: "aposentado",
            texto: "Aposentado",
          },
        },
        {
          value: {
            id: "8",
            tag: "func_publico",
            texto: "Funcionário Público",
          },
        },
        {
          value: {
            id: "115",
            tag: "empresario",
            texto: "Empresário",
          },
        },
        {
          value: {
            id: "134",
            tag: "outro",
            texto: "Outro",
          },
        },
      ],
      registrosImovel: [
        {
          value: {
            id: "135",
            tag: "sim",
            texto: "Sim",
          },
        },
        {
          value: {
            id: "136",
            tag: "nao",
            texto: "Não",
          },
        },
      ],
      imoveisFinanciado: [
        {
          value: {
            id: "142",
            tag: "sim",
            texto: "Sim",
          },
        },
        {
          value: {
            id: "143",
            tag: "nao",
            texto: "Não",
          },
        },
      ],
      tiposImovel: [
        {
          value: {
            id: "138",
            tag: "casa",
            texto: "Casa",
          },
        },
        {
          value: {
            id: "139",
            tag: "apartamento",
            texto: "Apartamento",
          },
        },
        {
          value: {
            id: "140",
            tag: "comercial",
            texto: "Comercial",
          },
        },
        {
          value: {
            id: "141",
            tag: "terreno",
            texto: "Terreno",
          },
        },
        {
          value: {
            id: "142",
            tag: "outro",
            texto: "Outro",
          },
        },
      ],
      relacoesEmpresa: [
        {
          value: {
            id: "138",
            tag: "administrador",
            texto: "Administrador",
          },
        },
        {
          value: {
            id: "139",
            tag: "gerente",
            texto: "Gerente",
          },
        },
        {
          value: {
            id: "140",
            tag: "socio",
            texto: "Sócio",
          },
        },
        {
          value: {
            id: "141",
            tag: "outros",
            texto: "Outros",
          },
        },
      ],
      portes: [
        {
          value: {
            id: "146",
            tag: "mei",
            texto: "MEI",
          },
        },
        {
          value: {
            id: "147",
            tag: "me",
            texto: "ME",
          },
        },
        {
          value: {
            id: "148",
            tag: "outros",
            texto: "OUTROS",
          },
        },
      ],

      fiador_conjuge: [
        {
          value: {
            id: "150",
            tag: "sim",
            texto: "Sim",
          },
        },
        {
          value: {
            id: "151",
            tag: "nao",
            texto: "Não",
          },
        },
      ],

      fiador_remunerado: [
        {
          value: {
            id: "152",
            tag: "sim",
            texto: "Sim",
          },
        },
        {
          value: {
            id: "153",
            tag: "nao",
            texto: "Não",
          },
        },
      ],

      hashAfiliado: localStorage.getItem("hashAfiliado"),

      form: {
        /*qualquer variável que se utizar simultaneamente
					em form 1 || 2 || 3 deve ficar aqui:	*/
        renda: "",
        //cep: "", ainda precisa ser integrado!
        data_nasc: "",
        selectedOcupacao: "",
        ...JSON.parse(sessionStorage.getItem("form")),
        // capturando o form da página anterior
      },

      sendedForm1: false,
      loanEmpPessoalVeiculo: false,
      marcasCarros: [],
      modelosCarros: [],
      form1: {
        selectedModelo: null,
        selectedMarca: null,
        selectedMarcaOld: 1,
        cep: "",
        id_cep: "form1_cep",
        cep_status: false,
        ano_veiculo: "",
        placa_veiculo: "",
        valor_veiculo: "",
      },

      sendedForm2: false,
      loanEmpPessoalImovel: false,
      form2: {
        cep: "",
        id_cep: "form2_cep",
        cep_status: false,
        cep_imovel: "",
        id_cep_imovel: "form2_cep_imovel",
        cep_imovel_status: false,
        numero_imovel: "",
        valor_imovel: "",
        saldo_devedor: "",

        selectedImovelFinanc: null,
        selectedRegImovel: null,
        selectedTipoImovel: "",
      },

      sendedForm3: false,
      loanEmpCNPJ: false,
      form3: {
        CNPJ: "",
        CNPJ_status: "",
        faturamento_mensal: "",
        selectedRelacEmpresa: "",
        selectedPort: "",
        nome_fiador: "",
        fiador_conjuge: "",
        fiador_remunerado: "",
      },
    };
  },
  watch: {
    "form1.selectedMarca": function () {
      this.form1.selectedModelo = null;
    },
    form1: {
      async handler(val) {
        // tentativa de sincronizar as cores entre os ceps
        /* document.getElementById(val.id_cep).backgroundColor = val.cep_status
          ? "#ffbbd8"
          : "#b9ffb9"; */

        val.cep_status = await this.validarCEP(
          val.cep,
          val.id_cep,
          val.cep_status
        );

        this.form2.cep = val.cep;
        this.form2.cep_status = val.cep_status;

        this.obterModelos(val.selectedMarca);
        /* fazendo validação */
      },
      deep: true,
      immediate: true,
      flush: true,
    },
    form2: {
      async handler(val) {
        // tentativa de sincronizar as cores entre os ceps
        /*document.getElementById(val.id_cep).backgroundColor = val.cep_status
          ? "#ffbbd8"
          : "#b9ffb9";*/

        val.cep_status = await this.validarCEP(
          val.cep,
          val.id_cep,
          val.cep_status
        );

        this.form1.cep = val.cep;
        this.form1.cep_status = val.cep_status;

        /* validando cep do próprio form */
        val.cep_imovel_status = await this.validarCEP(
          val.cep_imovel,
          val.id_cep_imovel,
          val.cep_imovel_status
        );

        /* fazendo validação */
      },
      deep: true,
      immediate: true,
      flush: true,
    },
    "form3.CNPJ": function (val) {
      this.form3.CNPJ_status = this.validarCNPJ(val);
    },
  },

  mounted() {
    // exibindo inicialmente campos em vermelho
    this.$refs.form1Ref.validate();
    this.$refs.form2Ref.validate();
    this.$refs.form3Ref.validate();

    // clear session storage
    sessionStorage.removeItem("session_id_form1");
    sessionStorage.removeItem("session_id_form2");
    sessionStorage.removeItem("session_id_form3");
    sessionStorage.removeItem("session_date_form1");
    sessionStorage.removeItem("session_date_form2");
    sessionStorage.removeItem("session_date_form3");
  },

  methods: {
    onSelecPort(v) {
      if(v.value.tag !== 'mei') {
      this.form3.fiador_conjuge = this.fiador_conjuge[1];
      this.form3.fiador_remunerado = this.fiador_remunerado[1];
      } else {
        this.form3.fiador_conjuge = "";
        this.form3.fiador_remunerado = "";
      }
    },
    async saveData(
      json,
      session_id_form,
      session_date_form,
      session_id_key,
      session_date_key,
      step,
      type,
      message
    ) {
      console.log("session_id_form :>> ", session_id_form);
      console.log("session_date_form :>> ", session_date_form);

      var config = {
        method: "post",
        url: Endpoints.BASE_URL + `/api/registerLead`,
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ._PolPB5lAzRq955YTkhDaDGHYcEUcMdVZzENnhJUJls",
          step: step !== "fim" ? "renda" : "fim",
          type: type,
          ...(session_id_form && { session_id: session_id_form }),
          ...(session_date_form && { session_date: session_date_form }),
        },
        data: json,
      };

      console.log("config", config);
      try {
        const response = await axios(config);
        console.log("response", response);

        // se status 200 ok
        if (response.status == 200) {
          console.log("response.data", response.data);
          // salvando o id da sessão
          sessionStorage.setItem(
            session_id_key,
            response.data.content.session_id
          );
          sessionStorage.setItem(
            session_date_key,
            response.data.content.session_date
          );
        }
        this.$toast.success(message);
        return true;
      } catch (error) {
        this.btnEnviarFormFim = false;
        this.sendedForm1 = false;
        this.sendedForm2 = false;
        this.sendedForm3 = false;
        console.log(error, error.response.status);
        this.$toast.error(
          "Algo deu errado ao salvar os dados. Verifique as informações!"
        );
        return false;
      }
    },

    async sendForm1(step) {
      try {
        this.btnEnviarFormFim = true;
        let session_id_form1 =
          sessionStorage.getItem("session_id_form1") || false;
        let session_date_form1 =
          sessionStorage.getItem("session_date_form1") || false;

        const jsonForm1 = [
          {
            tag: "dados_pessoais_pf",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Informe os dados pessoais",
            idQuiz: null,
            idPergunta: null,
            id_pergunta: "dados_pessoais_pf",
            id_resposta: 99999999,
            resposta_opcao: null,
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              email: this.form.email,
              nome: this.form.nome,
              ...(this.form.data_nasc && {
                dataNasc: this.form.data_nasc,
              }),
              celular: this.form.celular,
              cpf: this.form.cpf,
            },
            valor_emprestimo: `${this.form.valor_solicitado}`
              .replace(/\./g, "")
              .replace(",", "."),
            valor_parcelas: `${this.form.valor_parcela}`
              .replace(/\./g, "")
              .replace(",", "."),
            simulacao_selecionada: {},
          },
          {
            tag: "optIns",
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              optIns: ["notifica_whatsapp", "privacidade_termos_uso"],
            },
          },
          {
            tag: "renda",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Qual é o valor da sua renda?",
            idQuiz: "6",
            idPergunta: "9",
            id_pergunta: "renda",
            id_resposta: "valor",
            resposta_opcao: {
              id: "21",
              tipo: "texto-aberto",
              tipoDado: "monetario",
              tag: "valor",
              texto: "Informe o valor",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: { valor: this.form.renda },
          },
          {
            tag: "cep_lead",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Informe o CEP da sua residência",
            idQuiz: "6",
            idPergunta: "63",
            id_pergunta: "cep_lead",
            id_resposta: "cep_lead",
            resposta_opcao: {
              id: "108",
              tipo: "texto-aberto",
              tipoDado: "texto",
              tag: "cep_lead",
              texto: "Informe o CEP",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: { cep_lead: this.form1.cep },
          },
          {
            tag: "profissao",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Qual é sua ocupação?",
            idQuiz: "6",
            idPergunta: "4",
            id_pergunta: "profissao",
            id_resposta: this.form.selectedOcupacao.value.id,
            resposta_opcao: {
              id: this.form.selectedOcupacao.value.id,
              tipo: "alternativa",
              tipoDado: "numero",
              tag: this.form.selectedOcupacao.value.tag,
              texto: this.form.selectedOcupacao.value.texto,
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {},
          },
          {
            tag: "objetivo_emprestimo",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Qual o objetivo do empréstimo?",
            idQuiz: "6",
            idPergunta: "14",
            id_pergunta: "objetivo_emprestimo",
            id_resposta: this.form.selectedMotivo.value.tag,
            resposta_opcao: {
              id: this.form.selectedMotivo.value.id,
              tipo: "alternativa",
              tipoDado: "numero",
              tag: this.form.selectedMotivo.value.tag,
              texto: this.form.selectedMotivo.value.texto,
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {},
          },
          {
            tag: "veiculo_ano",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Qual o Ano do Modelo do veículo?",
            idQuiz: "6",
            idPergunta: "25",
            id_pergunta: "veiculo_ano",
            id_resposta: "ano",
            resposta_opcao: {
              id: "64",
              tipo: "texto-aberto",
              tipoDado: "numero",
              tag: "ano",
              texto: "Informe o Ano",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: { ano: this.form1.ano_veiculo },
          },
          {
            tag: "veiculo_marca_creditas",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Qual a marca/fabricante do veículo?",
            idQuiz: "6",
            idPergunta: "24",
            id_pergunta: "veiculo_marca_creditas",
            id_resposta: "marca_creditas",
            resposta_opcao: {
              id: "63",
              tipo: "texto-aberto",
              tipoDado: "texto",
              tag: "marca_creditas",
              texto: "Informe a Marca",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              marca_creditas: this.form1.selectedMarca,
            },
          },
          {
            tag: "veiculo_modelo_creditas",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Qual é o Modelo do veículo?",
            idQuiz: "6",
            idPergunta: "26",
            id_pergunta: "veiculo_modelo_creditas",
            id_resposta: "modelo_creditas",
            resposta_opcao: {
              id: "65",
              tipo: "texto-aberto",
              tipoDado: "texto",
              tag: "modelo_creditas",
              texto: "Informe o Modelo",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              modelo_creditas: this.form1.selectedModelo.value,
            },
          },
          {
            tag: "veiculo_placa",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Qual é a Placa do veículo?",
            idQuiz: "6",
            idPergunta: "27",
            id_pergunta: "veiculo_placa",
            id_resposta: "placa",
            resposta_opcao: {
              id: "66",
              tipo: "texto-aberto",
              tipoDado: "texto",
              tag: "placa",
              texto: "Informe a Placa",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: { placa: this.form1.placa_veiculo },
          },
          {
            tag: "veiculo_valor",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Qual o Valor do veículo?",
            idQuiz: "6",
            idPergunta: "28",
            id_pergunta: "veiculo_valor",
            id_resposta: "valor",
            resposta_opcao: {
              id: "67",
              tipo: "texto-aberto",
              tipoDado: "numero",
              tag: "valor",
              texto: "Informe o valor",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              valor: `${this.form1.valor_veiculo}`.replace(/[^0-9]/g, ""),
            },
          },
          {
            tag: "nome_responsavel_registro",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Nome Responsável Registro",
            idQuiz: "7",
            idPergunta: "79",
            id_pergunta: "nome_responsavel_registro",
            id_resposta: "nome_responsavel_registro",
            resposta_opcao: {
              id: this.form.nome_responsavel_registro,
              tipo: "texto-aberto",
              tipoDado: "texto",
              tag: "nome_responsavel_registro",
              texto: "Informe o nome",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              nome_responsavel_registro: this.form.nome_responsavel_registro,
            },
          },
        ];

        console.log(step, jsonForm1);
        this.sendedForm1 = true;
        // consumindo a api do front Selfcred
        // com o json gerado acima
        return await this.saveData(
          jsonForm1,
          session_id_form1,
          session_date_form1,
          `session_id_form1`,
          `session_date_form1`,
          step,
          "emprestimo-garantia-veiculo",
          "Quiz Veículo Enviado com Sucesso!"
        );
      } catch (e) {
        console.log("e: ", e);
        this.btnEnviarFormFim = false;
        this.$toast.error("Erro ao salvar os dados!");
      }
    },

    async sendForm2(step) {
      try {
        this.btnEnviarFormFim = true;
        let session_id_form2 =
          sessionStorage.getItem("session_id_form2") || false;
        let session_date_form2 =
          sessionStorage.getItem("session_date_form2") || false;

        const jsonForm2 = [
          {
            tag: "dados_pessoais_pf",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Informe os dados pessoais",
            idQuiz: null,
            idPergunta: null,
            id_pergunta: "dados_pessoais_pf",
            id_resposta: 99999999,
            resposta_opcao: null,
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              email: this.form.email,
              nome: this.form.nome,
              ...(this.form.data_nasc && {
                dataNasc: this.form.data_nasc,
              }),
              celular: this.form.celular,
              cpf: this.form.cpf,
            },
            valor_emprestimo: `${this.form.valor_solicitado}`
              .replace(/\./g, "")
              .replace(",", "."),
            valor_parcelas: `${this.form.valor_parcela}`
              .replace(/\./g, "")
              .replace(",", "."),
            simulacao_selecionada: {},
          },
          {
            tag: "optIns",
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              optIns: ["notifica_whatsapp", "privacidade_termos_uso"],
            },
          },
          {
            tag: "renda",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Qual é o valor da sua renda?",
            idQuiz: "7",
            idPergunta: "9",
            id_pergunta: "renda",
            id_resposta: "valor",
            resposta_opcao: {
              id: "21",
              tipo: "texto-aberto",
              tipoDado: "monetario",
              tag: "valor",
              texto: "Informe o valor",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: { valor: this.form.renda },
          },
          {
            tag: "cep_lead",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Informe o CEP da sua residência",
            idQuiz: "7",
            idPergunta: "63",
            id_pergunta: "cep_lead",
            id_resposta: "cep_lead",
            resposta_opcao: {
              id: "117",
              tipo: "texto-aberto",
              tipoDado: "texto",
              tag: "cep_lead",
              texto: "Informe o CEP",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: { cep_lead: this.form2.cep },
          },
          {
            tag: "profissao",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Qual é sua ocupação?",
            idQuiz: "7",
            idPergunta: "4",
            id_pergunta: "profissao",
            id_resposta: this.form.selectedOcupacao.value.id,
            resposta_opcao: {
              id: this.form.selectedOcupacao.value.id,
              tipo: "alternativa",
              tipoDado: "numero",
              tag: this.form.selectedOcupacao.value.tag,
              texto: this.form.selectedOcupacao.value.texto,
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {},
          },
          {
            tag: "objetivo_emprestimo",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Qual o objetivo do empréstimo?",
            idQuiz: "7",
            idPergunta: "14",
            id_pergunta: "objetivo_emprestimo",
            id_resposta: this.form.selectedMotivo.value.tag,
            resposta_opcao: {
              id: this.form.selectedMotivo.value.id,
              tipo: "alternativa",
              tipoDado: "numero",
              tag: this.form.selectedMotivo.value.tag,
              texto: this.form.selectedMotivo.value.texto,
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {},
          },
          {
            tag: "cep_imovel_garantia",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Informe o CEP do imóvel de garantia",
            idQuiz: "7",
            idPergunta: "70",
            id_pergunta: "cep_imovel_garantia",
            id_resposta: "cep_imovel",
            resposta_opcao: {
              id: "143",
              tipo: "texto-aberto",
              tipoDado: "texto",
              tag: "cep_imovel",
              texto: "Informe o CEP",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: { cep_imovel: this.form2.cep_imovel },
          },
          {
            tag: "numero_imovel_garantia",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Informe o número do endereço deste imóvel",
            idQuiz: "7",
            idPergunta: "71",
            id_pergunta: "numero_imovel_garantia",
            id_resposta: "numero_imovel",
            resposta_opcao: {
              id: "144",
              tipo: "texto-aberto",
              tipoDado: "texto",
              tag: "numero_imovel",
              texto: "Informe o Número",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: { numero_imovel: this.form2.numero_imovel },
          },
          {
            tag: "valor_imovel_garantia",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Qual é o valor de mercado deste imóvel?",
            idQuiz: "7",
            idPergunta: "72",
            id_pergunta: "valor_imovel_garantia",
            id_resposta: "valor_imovel",
            resposta_opcao: {
              id: "145",
              tipo: "texto-aberto",
              tipoDado: "texto",
              tag: "valor_imovel",
              texto: "Informe o valor",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: { valor_imovel: this.form2.valor_imovel },
          },
          {
            tag: "debito_imovel_garantia",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta:
              "Qual o saldo devedor para a quitação do imóvel (Se houver)",
            idQuiz: "7",
            idPergunta: "73",
            id_pergunta: "debito_imovel_garantia",
            id_resposta: "debito_imovel",
            resposta_opcao: {
              id: "146",
              tipo: "texto-aberto",
              tipoDado: "texto",
              tag: "debito_imovel",
              texto: "Informe o valor",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: { debito_imovel: this.form2.saldo_devedor },
          },
          {
            tag: "imovel_financiado",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "O Imóvel é financiado?",
            idQuiz: "7",
            idPergunta: "78",
            id_pergunta: "imovel_financiado",
            id_resposta: this.form2.selectedImovelFinanc.value.id,
            resposta_opcao: {
              id: this.form2.selectedImovelFinanc.value.id,
              tipo: "alternativa",
              tipoDado: "numero",
              tag: this.form2.selectedImovelFinanc.value.tag,
              texto: this.form2.selectedImovelFinanc.value.tag,
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {},
          },
          {
            tag: "registro_imovel_garantia",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Você tem o registo/escritura desse imóvel?",
            idQuiz: "7",
            idPergunta: "74",
            id_pergunta: "registro_imovel_garantia",
            id_resposta: this.form2.selectedRegImovel.value.id,
            resposta_opcao: {
              id: this.form2.selectedRegImovel.value.id,
              tipo: "alternativa",
              tipoDado: "numero",
              tag: this.form2.selectedRegImovel.value.tag,
              texto: this.form2.selectedRegImovel.value.tag,
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {},
          },
          {
            tag: "tipo_imovel_garantia",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Qual o tipo deste imóvel?",
            idQuiz: "7",
            idPergunta: "75",
            id_pergunta: "tipo_imovel_garantia",
            id_resposta: this.form2.selectedTipoImovel.value.id,
            resposta_opcao: {
              id: this.form2.selectedTipoImovel.value.id,
              tipo: "alternativa",
              tipoDado: "numero",
              tag: this.form2.selectedTipoImovel.value.tag,
              texto: this.form2.selectedTipoImovel.value.texto,
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {},
          },
          {
            tag: "nome_responsavel_registro",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Nome Responsável Registro",
            idQuiz: "7",
            idPergunta: "79",
            id_pergunta: "nome_responsavel_registro",
            id_resposta: "nome_responsavel_registro",
            resposta_opcao: {
              id: this.form.nome_responsavel_registro,
              tipo: "texto-aberto",
              tipoDado: "texto",
              tag: "nome_responsavel_registro",
              texto: "Informe o nome",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              nome_responsavel_registro: this.form.nome_responsavel_registro,
            },
          },
        ];

        console.log(jsonForm2, step);
        this.sendedForm2 = true;
        // consumindo a api do front Selfcred
        // com o json gerado acima
        return await this.saveData(
          jsonForm2,
          session_id_form2,
          session_date_form2,
          `session_id_form2`,
          `session_date_form2`,
          step,
          "emprestimo-garantia-imovel",
          "Quiz Imóvel Enviado com Sucesso!"
        );
      } catch (e) {
        this.btnEnviarFormFim = false;
        console.log("e :>> ", e);
        this.$toast.error("Erro ao salvar os dados!");
      }
    },

    async sendForm3(step) {
      try {
        this.btnEnviarFormFim = true;
        let session_id_form3 =
          sessionStorage.getItem("session_id_form3") || false;
        let session_date_form3 =
          sessionStorage.getItem("session_date_form3") || false;

        console.log(`aqui`, session_id_form3, session_date_form3);

        const jsonForm3 = [
          {
            tag: "solicitante",
            tipoQuiz: "pessoal",
            subtipoQuiz: "padrao",
            pergunta: "Para quem é o empréstimo?",
            idQuiz: "1",
            idPergunta: "3",
            id_pergunta: "solicitante",
            id_resposta: "empresa",
            resposta_opcao: {
              id: "4",
              tipo: "alternativa",
              tipoDado: "numero",
              tag: "empresa",
              texto: "Para sua Empresa",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {},
            valor_emprestimo: `${this.form.valor_solicitado}`
              .replace(/\./g, "")
              .replace(",", "."),
            valor_parcelas: `${this.form.valor_parcela}`
              .replace(/\./g, "")
              .replace(",", "."),
            simulacao_selecionada: {},
          },
          {
            tag: "objetivo",
            tipoQuiz: "empresa",
            subtipoQuiz: "pessoa juridica",
            pergunta: "Qual o objetivo do empréstimo?",
            idQuiz: "2",
            idPergunta: "14",
            id_pergunta: "objetivo",
            id_resposta: this.form.selectedMotivo.value.tag,
            resposta_opcao: {
              id: this.form.selectedMotivo.value.id,
              tipo: "alternativa",
              tipoDado: "numero",
              tag: this.form.selectedMotivo.value.tag,
              texto: this.form.selectedMotivo.value.texto,
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {},
          },
          {
            tag: "dados_pessoais_pj",
            tipoQuiz: "empresa",
            subtipoQuiz: "pessoa juridica",
            pergunta: "Informe os dados pessoais",
            idQuiz: null,
            idPergunta: null,
            id_pergunta: "dados_pessoais_pj",
            id_resposta: 99999999,
            resposta_opcao: null,
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              cnpj: this.form3.CNPJ,
              cpf: this.form.cpf,
              nome: this.form.nome,
              celular: this.form.celular,
              email: this.form.email,
            },
          },
          {
            tag: "faturamento_mensal",
            tipoQuiz: "empresa",
            subtipoQuiz: "pessoa juridica",
            pergunta: "Qual é o faturamento mensal?",
            idQuiz: "2",
            idPergunta: "76",
            id_pergunta: "faturamento_mensal",
            id_resposta: "valor",
            resposta_opcao: {
              id: "137",
              tipo: "texto-aberto",
              tipoDado: "monetario",
              tag: "valor",
              texto: "Informe o valor",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              valor: this.form3.faturamento_mensal,
            },
          },
          {
            tag: "relacao_empresa",
            tipoQuiz: "empresa",
            subtipoQuiz: "pessoa juridica",
            pergunta: "Qual a relação com a empresa?",
            idQuiz: "2",
            idPergunta: "77",
            id_pergunta: "relacao_empresa",
            id_resposta: this.form3.selectedRelacEmpresa.value.id,
            resposta_opcao: {
              id: this.form3.selectedRelacEmpresa.value.id,
              tipo: "alternativa",
              tipoDado: "numero",
              tag: this.form3.selectedRelacEmpresa.value.tag,
              texto: this.form3.selectedRelacEmpresa.value.texto,
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {},
          },
          {
            tag: "optIns",
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              optIns: ["notifica_whatsapp", "privacidade_termos_uso"],
            },
          },
          {
            tag: "nome_responsavel_registro",
            tipoQuiz: "refinanciamento",
            subtipoQuiz: "padrao",
            pergunta: "Nome Responsável Registro",
            idQuiz: "7",
            idPergunta: "79",
            id_pergunta: "nome_responsavel_registro",
            id_resposta: "valor",
            resposta_opcao: {
              id: this.form.nome_responsavel_registro,
              tipo: "texto-aberto",
              tipoDado: "texto",
              tag: "valor",
              texto: "Informe o valor",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              nome_responsavel_registro: this.form.nome_responsavel_registro,
            },
          },
          {
            tag: "porte_empresa",
            tipoQuiz: "empresa",
            subtipoQuiz: "pessoa juridica",
            pergunta: "Porte da Empresa",
            idQuiz: "2",
            idPergunta: "81",
            id_pergunta: "porte_empresa",
            id_resposta: this.form3.selectedPort.value.id,
            resposta_opcao: {
              id: this.form3.selectedPort.value.id,
              tipo: "alternativa",
              tipoDado: "texto",
              tag: this.form3.selectedPort.value.tag,
              texto: this.form3.selectedPort.value.texto,
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {},
          },
          {
            tag: "nome_fiador",
            tipoQuiz: "empresa",
            subtipoQuiz: "pessoa juridica",
            pergunta: "Nome do Fiador / Aval.",
            idQuiz: "2",
            idPergunta: "82",
            id_pergunta: "nome_fiador",
            id_resposta: "valor",
            resposta_opcao: {
              id: this.form3.nome_fiador,
              tipo: "texto-aberto",
              tipoDado: "texto",
              tag: "valor",
              texto: "Nome Fiador",
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {
              nome_fiador: this.form3.nome_fiador,
            },
          },
        ];

        if (this.form3.selectedPort.value.tag === "mei") {
          jsonForm3.push({
            tag: "fiador_conjuge",
            tipoQuiz: "empresa",
            subtipoQuiz: "pessoa juridica",
            pergunta: "Porte da Empresa",
            idQuiz: "2",
            idPergunta: "83",
            id_pergunta: "fiador_conjuge",
            id_resposta: this.form3.fiador_conjuge.value.id,
            resposta_opcao: {
              id: this.form3.fiador_conjuge.value.id,
              tipo: "alternativa",
              tipoDado: "texto",
              tag: this.form3.fiador_conjuge.value.tag,
              texto: this.form3.fiador_conjuge.value.texto,
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {},
          });
          jsonForm3.push({
            tag: "fiador_remunerado",
            tipoQuiz: "empresa",
            subtipoQuiz: "pessoa juridica",
            pergunta: "Porte da Empresa",
            idQuiz: "2",
            idPergunta: "84",
            id_pergunta: "fiador_remunerado",
            id_resposta: this.form3.fiador_remunerado.value.id,
            resposta_opcao: {
              id: this.form3.fiador_remunerado.value.id,
              tipo: "alternativa",
              tipoDado: "texto",
              tag: this.form3.fiador_remunerado.value.tag,
              texto: this.form3.fiador_remunerado.value.texto,
              pontos: 0,
            },
            hash_afiliado: this.hashAfiliado,
            resposta_campos: {},
          });
        }

        console.log(jsonForm3, step);
        this.sendedForm3 = true;
        // consumindo a api do front Selfcred
        // com o json gerado acima
        return await this.saveData(
          jsonForm3,
          session_id_form3,
          session_date_form3,
          `session_id_form3`,
          `session_date_form3`,
          step,
          "padrao",
          "Quiz CNPJ Enviado com Sucesso!"
        );
      } catch (e) {
        this.btnEnviarFormFim = false;
        console.log("e :>> ", e);
        this.$toast.error("Erro ao salvar os dados!");
      }
    },

    validadorGeral() {
      try {
        if (this.aba.Veiculo) {
          Object.keys(this.form1).forEach((key) => {
            // se algum valor inserido ser false:
            if (!this.form1[key]) throw "validationFail";
          });

          if (
            this.validarDifZero(this.form.renda) &&
            this.validarData(this.form.data_nasc) &&
            this.validarAno(this.form1.ano_veiculo) &&
            this.validarPlaca(this.form1.placa_veiculo) &&
            this.validarDifZero(this.form1.valor_veiculo)
          )
            null;
          else throw "validationFail";
        }

        if (this.aba.Imovel) {
          Object.keys(this.form2).forEach((key) => {
            // se algum valor inserido ser false:
            if (!this.form2[key]) throw "validationFail";
          });

          if (
            this.validarDifZero(this.form.renda) &&
            this.validarData(this.form.data_nasc) &&
            this.validarDifZero(this.form2.valor_imovel)
          )
            null;
          else throw "validationFail";
        }

        if (this.aba.CNPJ) {
          Object.keys(this.form3).forEach((key) => {
            // se algum valor inserido ser false:
            if (!this.form3[key]) throw "validationFail";
          });
        }

        return true;
      } catch (e) {
        this.$toast.error("Preencha todos os campos em vermelho corretamente!");
        return false;
      }
    },

    validarPlaca(placa) {
      return validarPlaca(placa);
    },

    validarAno(ano) {
      return ano.length === 4 && ano.match(/^[0-9]+$/);
    },

    validarDifZero(val) {
      return validarDifZero(val);
    },

    validarCNPJ(cnpj) {
      return validarCNPJ(cnpj);
    },

    async validarCEP(cep, doc_id, cep_status) {
      let resultCEP = false;
      cep_status = false;

      if (cep.length == 9) {
        //console.log("cep :>> ", cep);
        var config = {
          method: "get",
          url: "https://viacep.com.br/ws/" + cep + "/json/",
        };

        await axios(config)
          .then(function (response) {
            //console.log("response :>> ", response);
            resultCEP = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      if (resultCEP) {
        Object.keys(resultCEP).forEach(function (key) {
          if (key.indexOf("erro") > -1) {
            cep_status = false;
          } else {
            cep_status = true;
          }
        });
      }

      // style="background-color: rgb(185, 255, 185);"

      console.log(`cep_status :>> ${cep_status}`);
      /* const element = document.getElementById(doc_id);

      if (cep_status) element.style.backgroundColor = "#A3E0A3";
      else element.style.backgroundColor = "#ffbbd8"; */

      return cep_status;
    },

    validarData(data) {
      return validarData(data);
    },

    async obterMarcas() {
      // reset valores
      this.form1.selectedMarca = null;
      this.marcasCarros = [];
      this.modelosCarros = [];

      if (
        this.validarAno(this.form1.ano_veiculo) &&
        `${this.form1.ano_veiculo}`.length == 4
      ) {
        var config = {
          method: "get",
          url: `${Endpoints.CREDITAS_URL}/marcasVeiculo/${this.form1.ano_veiculo}`,
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ._PolPB5lAzRq955YTkhDaDGHYcEUcMdVZzENnhJUJls",
          },
        };

        try {
          let result;
          await axios(config)
            .then(function (res) {
              result = res;
            })
            .catch(function (error) {
              console.log(error);
              throw error;
            });

          this.marcasCarros = [];

          result.data.items.map((m) => {
            this.marcasCarros.push(m.name);
          });
        } catch (e) {
          console.log("e :>> ", e);
          // toast de erro infinito
          this.$toast.error(
            "Erro ao processar o ano, por favor digite novamente!"
          );
        }
      }
    },

    async obterModelos(marca) {
      if (this.form2.selectedMarcaOld != marca) {
        //atualizando o campo selectedMarcaOld
        this.form2.selectedMarcaOld = marca;
        var config = {
          method: "get",
          url: `${Endpoints.CREDITAS_URL}/modelosVeiculo/${this.form1.ano_veiculo}/${marca}`,
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ._PolPB5lAzRq955YTkhDaDGHYcEUcMdVZzENnhJUJls",
          },
        };

        let result;
        await axios(config)
          .then(function (res) {
            result = res;
          })
          .catch(function (error) {
            console.log(error);
          });

        this.modelosCarros = result.data.items.map((m) => {
          return {
            text: `${m.model} - ${m.modelVersion} - ${m.numberOfDoors}p - ${m.fuelType} - ${m.accessoryPackage}`,
            value: `{"model":"${m.model}","modelVersion":"${m.modelVersion}","numberOfDoors":"${m.numberOfDoors}","fuelType":"${m.fuelType}","accessoryPackage":"${m.accessoryPackage}"}`,
          };
        });
      }
    },

    async fimForm() {
      try {
        if (this.validadorGeral()) {
          const results = [];
          if (this.aba.Veiculo) results.push(await this.sendForm1("fim"));
          if (this.aba.Imovel) results.push(await this.sendForm2("fim"));
          if (this.aba.CNPJ) results.push(await this.sendForm3("fim"));

          const hasCompleted = !results.some((e) => !e);

          if (hasCompleted) {
            sessionStorage.removeItem("form");
            this.$router.push("/dashboard");
          }
        }
      } catch (e) {
        console.log("e :>> ", e);

        this.$toast.error("Algo deu errado, tente novamente!");
      }
    },

    async fimFormDepois() {
      try {
        if (this.aba.Veiculo) await this.sendForm1();
        if (this.aba.Imovel) await this.sendForm2();
        if (this.aba.CNPJ) await this.sendForm3();

        this.$router.push("/dashboard");
      } catch (e) {
        console.log("e :>> ", e);
        this.$toast.error("Algo deu errado, tente novamente!");
      }
    },
  },
};
</script>

<style>
.request-page {
  background-color: white;
  min-height: 95vh;
}

.card-header {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}

.card-body {
  border-radius: 0.5rem;
}

.v-tab--active {
  background-color: white !important;
  color: black !important;
  /* round border top */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.v-text-field__details {
  display: none !important;
}

.theme--light.v-label {
  color: #2da80e !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot::before {
  border-color: transparent !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot {
  color: transparent !important;
}

.v-icon {
  color: #2da80e !important;
}

@media (min-width: 970px) {
  .scroll-container {
    overflow: auto;
    height: calc(100vh - 72px);
  }
}
</style>